<template>
  <div class="upi-collect xl:pt-6 xl:pb-15 xl:mx-10 <xl:mx-[28px] <xl:mb-[80px] flex flex-col">
    <CountDown></CountDown>
    <div class="section flex-1 flex flex-col justify-between">
      <div>
        <h4 class="text-$theme-color xl:text-xl xl:mt-12 xl:mb-8 <xl:text-[20px] <xl:mt-[24px] <xl:mb-[20px]">Pay using Wallet</h4>
        <button class="rounded-[26PX] bg-$theme-color text-$color-fff w-full xl:bottom-15 xl:h-19 xl:text-3xl <xl:h-[64px] <xl:text-[32px]" @click="walletPay">Pay using Wallet</button>
        <!-- <div class="overflow-hidden border rounded-[26PX] xl:max-h-40 xl:p-4 <xl:px-[10px] <xl:py-[14px] <xl:max-h-[196px]">
          <div class="flex flex-wrap justify-start items-center xl:max-h-32 <xl:max-h-[168px] rounded-[26PX] overflow-y-scroll">
            <div class="w-1/4 text-center xl:pb-3 <xl:pb-[14px]" v-for="item in 9" :key="item">
              <div class="rounded-[26PX] xl:w-14.5 xl:h-14.5 <xl:w-[72px] <xl:h-[72px] bg-$theme-color-light-08 inline-flex justify-center items-center flex-col">
                <img class="xl:h-9 xl:w-9 <xl:w-[40px] <xl:h-[40px]" src="" alt="">
                <span class="mt-[4PX] xl:text-[8PX] <xl:text-[12px]">ICICI Bank</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div>
        <h4 class="text-$theme-color xl:text-xl xl:my-5 <xl:text-[20px] <xl:mt-[24px] <xl:mb-[10px]">Pay using UPI ID</h4>
        <van-field
          class="vpa-input border border-$theme-color rounded-[26PX]  xl:h-19 <xl:h-[64px]"
          :class="data.showError ? 'border-[#D43030]' : ''"
          center
          v-model="data.vpa"
          placeholder="Please enter your UPI ID"
          @update:model-value="data.showError = false"
        />
        <p class="error-tips text-[#D43030] xl:text-lg xl:mt-[8PX] xl:mb-[20PX] <xl:text-[18px] <xl:mt-[4PX] <xl:mb-[12PX]" :class="{'invisible' : !data.showError}">
          Please enter the correct UPI ID
        </p>
        <button class="rounded-[26PX] bg-$theme-color text-$color-fff w-full xl:h-19 xl:text-3xl <xl:h-[64px] <xl:text-[32px] <xl:bottom-[80px]" @click="checkVpaVal">Verify and Pay</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {reactive} from "vue";
import {useStore} from "vuex";
import {upiCollectPayment, upiIntentPayment, getPaymentResult, upiVpaCheck} from "@/api/payment";
import CountDown from "@/components/CountDown.vue";
import openModal from '../utils/dialog';
import {openNewWindow} from "@/utils/utils";

const route = useRoute();
const router = useRouter();
const store = useStore();

const data = reactive({
  vpa: "",
  showError: false,
});

// 检测vpa
let checkVpaVal = async () => {
  let validator = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(data.vpa);
  if(!validator){
    data.showError = true;
  }else {
    let res = await upiVpaCheck({customerVpa: data.vpa})
    if (res.status === "SUCCESS") {
      data.showError = false;
      // 发起支付
      intentPay(data.vpa);
    } else {
      data.showError = true;
    }
  }
};

// 发起支付
let intentPay = async (vpaCode) => {
  let data = await upiCollectPayment({payerVpaID: vpaCode});
  store.commit("updateOpsStatus", true);
  if (data.status === "SUCCESS" || data.status === "PENDING") {
    // 跳下个页面-倒计时轮询结果页
    router.replace({
      path: "/upiCountdown",
      query: {
        upiId: vpaCode,
      },
    });
  } else {
    // 失败跳转失败页面
    router.replace({
      path: "/paymentResult",
      // query: {
      //   type: "UPI",
      // },
    });
  }
};
let onetimeId = sessionStorage.getItem("onetimeId");
let dialog = null;
// UPI icon
let walletPay = async () => {
  let data = await upiIntentPayment({onetimeId});
  if (data.intentUrl) {
    let url = data.intentUrl || ''
    // loading
    store.commit("updateOpsStatus", true);
    dialog = openModal({
      title: 'Waiting for payment...',
      content: 'Please login to your app and accept payment from MPurse',
      type: 'loading',
      cancelText:'',
      confirmText:'',
    });
    upiResult();
    openNewWindow(url)
    // window.open(url, "_blank", "width=1300,height=800,top = 200, left=400");
    // 查询支付结果 第三方支付窗口是否关闭childrenWin.closed
  }
}
let getResult;
//UPI支付结果
let upiResult = async () => {
  try {
    let data = await getPaymentResult({},{noAlert:true});
    if ( data.status === "SUCCESS" || data.status === "FAILURE") {
      //已支付或支付失败
      clearTimeout(getResult);
      dialog.unmount(); //关闭Waiting
      router.replace({
        path: "/paymentResult",
        query: {
          type: "UPI",
        },
      });
    } else {
      //未支付重试
      clearTimeout(getResult);
      getResult = setTimeout(() => {
        upiResult();
      }, 1000);
    }
  } catch (error) {
    //未支付重试
    clearTimeout(getResult);
    getResult = setTimeout(() => {
      upiResult();
    }, 1000);
  }
};
</script>
